import { useAppDispatch } from "@/store";
import { authActions, selectAuth } from "@/store/slices/auth";
import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import offerpopup from "@/assets/images/sign.jpg";
import logo from "@/assets/images/logo-pop-new.png";
import Image from "next/image";
import Link from "next/link";
import httpClient from "@/api/http-client";
import endpoints from "@/api/endpoints";
import { toast } from "react-toastify";
import { removeCookies } from "cookies-next";

const CustomerConsent = () => {
  const dispatch = useAppDispatch();
  const authState = useSelector(selectAuth);
  const logout = () => {
    if (window.Moengage) {
      window.Moengage.track_event("Logout", {
        mobile: authState.user?.mobile,
      });
      window.Moengage.destroy_session();
    }

    removeCookies("token");

    // @ts-ignore
    if (typeof ChatGen !== "undefined") {
      // @ts-ignore
      ChatGen.identify({
        custom_attributes: {
          senco_user_id: null,
        },
      });
    }
    toast("Logged out successfully", {
      type: "info",
      toastId: "auth-logout-success",
    });
    const basePath = process.env.NEXT_PUBLIC_BASE_PATH || "/";
    window.location.href = basePath;
  };
  const statusChange = async (type: any) => {
    try {
      const { data } = await httpClient.post(
        endpoints.auth.updateConsentForm(),
        { status: type }
      );
      if (type == "accept") {
        dispatch(authActions.setIsConsentModalOpen(false));
      } else {
        dispatch(authActions.setIsConsentModalOpen(false));
        logout();
      }
    } catch (error: any) {
      if (error.response.data?.message) {
        toast(error.response.data.message, {
          type: "error",
          toastId: "auth-consent-error",
        });
      } else {
        toast("Something went wrong! Try again!", {
          type: "error",
          toastId: "auth-consent-error",
        });
      }

      logout();
    }
  };
  return (
    <Modal
      show={authState.consentModalOpen}
      dialogClassName="modal-lg border-0 customer_consent__pop"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="p-0 custom_border_0"></Modal.Header>
      <Modal.Body className="p-0">
        <div className="customer_consent_main">
          <div className="border-pop">
            <div className="logo_pop">
              <Image src={logo} alt="" />
            </div>
            <div className="heading">Personal Data Processing Consent Form</div>
            <ul className="customer_consent_list">
              <li>
                I, {authState.user?.name} have been provided with the Senco Gold
                Limited Privacy Policy and Privacy Notice, which are also
                available on its website{" "}
                <a
                  className="link"
                  href="https://sencogoldanddiamonds.com/data-privacy-notice"
                >
                  https://sencogoldanddiamonds.com/data-privacy-notice
                </a>
                , and have understood the contents thereof.
              </li>
              <li>
                I understand that the Privacy Notice and Privacy Policy
                mentioned in Paragraph 1 above provide information about how my
                personal data may be collected, used and disclosed by the{" "}
                <a>Senco Gold Limited</a> or its partners/ associates.
              </li>
              <li>
                I understand that my personal data may be collected only with my
                valid consent, and that it may be used or processed only in
                accordance with the Privacy Policy and Privacy Notice issued{" "}
                <a>by Senco Gold Limited</a>.
              </li>
              <li>
                4. I understand that my personal data may be shared only with my
                consent, and for the following purposes{" "}
                <a>
                  such as to notify about new services or special promotional
                  programs, or send offers or information as opted in.
                </a>
              </li>
              <li>
                I understand that I have certain rights that I enjoy as a data
                principal over my personal data, in relation to its collection,
                use and processing. These rights relate to confirmation and
                access, correction and erasure, objection of disclosure, and
                portability of my personal data. These rights are available to
                me in the manner set out in the Personal Data Protection Act and
                the Privacy Policy and Privacy Notice issued by{" "}
                <a>Senco Gold Limited</a>.
              </li>
              <li>
                I understand that the terms of the Privacy Notice may change,
                and I will be notified of the same before or at the time any
                further collection, use or processing of my personal data takes
                place. I understand that I will be provided the opportunity to
                provide fresh consent for the same.
              </li>
              <li>
                I understand that I have the right to revoke my consent at any
                time in the manner specified under the Privacy Notice or Opt Out
                anytime
              </li>
            </ul>
            <p>
              Having read and understood the points mentioned above, I consent
              to allowing Senco Gold Limited to collect, use, share or process
              such personal data as may be necessary.
            </p>
            <div className="custom-fill">
              <Button
                aria-label="reject"
                variant="dark"
                className="pe-4 ps-4"
                onClick={() => statusChange("reject")}
                role="button"
              >
                DENY
              </Button>
              <Button
                role="button"
                aria-label="accept"
                className="red_button pe-4 ps-4"
                onClick={() => statusChange("accept")}
              >
                ACCEPT
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default CustomerConsent;
