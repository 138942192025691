import {
  authActions,
  fetchAuthLogin,
  fetchAuthUser,
} from "@/store/slices/auth";
import { fetchCartItems } from "@/store/slices/cart";
import { phoneRegExp } from "@/utils/regex";
import { setCookies } from "cookies-next";
import { useFormik } from "formik";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import * as Yup from "yup";
import endpoints from "@/api/endpoints";
import styles from "@/styles/forgotpassword.module.scss";
import httpClient from "@/api/http-client";
import { useAppDispatch } from "@/store";
import { fetchWishlistItems } from "@/store/slices/wishlist";
import { initTryOnTracking } from "@/lib/tryOnTracking";
import OtpInput from "react-otp-input";
import Link from "next/link";

const RESEND_OTP_INTERVAL_SECONDS = 30;

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [sendOtpLoading, setSendOtpLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [otp_uid, setUid] = useState(false);
  const [otpData, setOTPData] = useState<any>(null);
  const [consent, setConsent] = useState<any>(false);
  const [resendTimeout, setResendTimeout] = useState(0);

  useEffect(() => {
    if (resendTimeout > 0) {
      const timer = setInterval(() => {
        setResendTimeout(resendTimeout - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [resendTimeout]);

  const isConcent = async (token: any) => {
    try {
      const { data } = await httpClient.get(endpoints.auth.getConsentForm(), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.status) {
        setStep(3);
        // dispatch(authActions.setIsConsentModalOpen(true));
      } else {
        setCookies("token", token, { path: "/" });
        const { payload: user }: any = await dispatch(fetchAuthUser());
        toast("Welcome " + user.name, {
          type: "success",
          toastId: "auth-login-success",
        });
        if (window.Moengage) {
          window.Moengage.track_event("Login", {
            mobile: user.mobile,
          });
          window.Moengage.add_first_name(user.name);
          window.Moengage.add_mobile(user.mobile);
          window.Moengage.add_unique_user_id(user.uuid);
          if (user.email) {
            window.Moengage.add_email(user.email);
          }
        }
        dispatch(fetchCartItems());
        dispatch(fetchWishlistItems(user.id));
        dispatch(authActions.setIsModalOpen(false));
        initTryOnTracking(user.id);
      }
    } catch (error: any) {
      console.log("error", error);
    }
    setLoading(false);
  };

  const statusChange = async (type: any) => {
    setLoading(true);
    try {
      const { data } = await httpClient.post(
        endpoints.auth.updateConsentForm(),
        { status: type },
        {
          headers: {
            Authorization: `Bearer ${otpData.access_token}`,
          },
        }
      );
      if (data.status) {
        setCookies("token", otpData.access_token, { path: "/" });
        const { payload: user }: any = await dispatch(fetchAuthUser());
        toast("Welcome " + user.name, {
          type: "success",
          toastId: "auth-login-success",
        });
        if (window.Moengage) {
          window.Moengage.track_event("Login", {
            mobile: user.mobile,
          });
          window.Moengage.add_first_name(user.name);
          window.Moengage.add_mobile(user.mobile);
          window.Moengage.add_unique_user_id(user.uuid);
          if (user.email) {
            window.Moengage.add_email(user.email);
          }
        }
        dispatch(fetchCartItems());
        dispatch(fetchWishlistItems(user.id));
        dispatch(authActions.setIsModalOpen(false));
        initTryOnTracking(user.id);
        dispatch(authActions.setIsModalOpen(false));

        // if (type == "accept") {
        //   dispatch(authActions.setIsConsentModalOpen(false));
        // } else {
        //   dispatch(authActions.setIsConsentModalOpen(false));
        //   logout();
        // }
      }
    } catch (error: any) {
      if (error.response.data?.message) {
        toast(error.response.data.message, {
          type: "error",
          toastId: "auth-consent-error",
        });
      } else {
        toast("Something went wrong! Try again!", {
          type: "error",
          toastId: "auth-consent-error",
        });
      }
      setLoading(false);
      // logout();
    }
  };

  const formik = useFormik({
    initialValues: {
      mobile: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .matches(phoneRegExp, "Mobile number is not valid")
        .test(
          "len",
          "Mobile number needs to be exactly 10 digits",
          (val: any) => val?.toString().length === 10
        )
        .required("Required"),
    }),
    onSubmit: async (values) => {
      if (values.mobile.trim()) {
        setSendOtpLoading(true);
        try {
          const { data } = await httpClient.post(
            endpoints.auth.loginSendOtp(),
            {
              mobile: values.mobile,
            }
          );
          setUid(data.otp_uuid);

          toast(data.message, {
            type: "success",
            toastId: "auth-otp-success",
          });
          setStep(2);
          setResendTimeout(RESEND_OTP_INTERVAL_SECONDS);
          console.log("success");
        } catch (error: any) {
          if (error.response.data?.message) {
            toast(error.response.data.message, {
              type: "error",
              toastId: "auth-otp-error",
            });
          } else {
            toast("Something went wrong! Try again!", {
              type: "error",
              toastId: "auth-otp-error",
            });
          }
        }
        setSendOtpLoading(false);
      } else {
        toast("Please enter your registered mobile number", {
          type: "error",
          toastId: "auth-login-error",
        });
      }
    },
  });

  const otpFormik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required("OTP is required")
        .min(6, "Must be exactly 6 digits")
        .max(6, "Must be exactly 6 digits"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const { data } = await httpClient.post(
          endpoints.auth.loginVerifyOtp(),
          {
            mobile: formik.values.mobile,
            otp: values.otp,
            otp_uuid: otp_uid,
          }
        );
        setOTPData(data);
        isConcent(data?.access_token);
      } catch (error: any) {
        if (error.response.data?.message) {
          toast(error.response.data.message, {
            type: "error",
            toastId: "auth-otp-error",
          });
        } else {
          toast("Something went wrong! Try again!", {
            type: "error",
            toastId: "auth-otp-error",
          });
        }
      }
      setLoading(false);
    },
  });

  const handleResendOtp = async () => {
    if (resendTimeout > 0) return;
    setSendOtpLoading(true);
    try {
      const { data } = await httpClient.post(endpoints.auth.loginSendOtp(), {
        mobile: formik.values.mobile,
      });
      setUid(data.otp_uuid);
      toast(data.message, {
        type: "success",
        toastId: "auth-otp-success",
      });
      setResendTimeout(RESEND_OTP_INTERVAL_SECONDS);
    } catch (error: any) {
      if (error.response.data?.message) {
        toast(error.response.data.message, {
          type: "error",
          toastId: "auth-otp-error",
        });
      } else {
        toast("Something went wrong! Try again!", {
          type: "error",
          toastId: "auth-otp-error",
        });
      }
    }
    setSendOtpLoading(false);
  };

  return (
    <div className={styles.login_container}>
      <div className={styles.login_image}>
        <Image
          src="https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/EXfVWvIz5SFqWvT8MjQ2kNZT0z7fLpusbEtpVMCo.jpeg"
          alt="Login image"
          width={399}
          height={532}
        />
      </div>
      {step == 1 && (
        <div className={styles.contact_form_wrapper}>
          <form onSubmit={formik.handleSubmit}>
            <h1>Login</h1>
            <div className="form-item">
              <input
                type="tel"
                placeholder="Mobile No."
                autoComplete="off"
                maxLength={10}
                {...formik.getFieldProps("mobile")}
              />
              {formik.touched.mobile && formik.errors.mobile ? (
                <small className="text-danger">{formik.errors.mobile}</small>
              ) : null}
            </div>
            <button
              role="button"
              aria-label="Request OTP"
              className={styles.submit_btn}
              type="submit"
              disabled={loading || sendOtpLoading}
            >
              Request OTP{" "}
              {sendOtpLoading && <Spinner animation="border" size="sm" />}
            </button>
            <div className="row">
              <div className="col-md-7">
                <p>
                  New here?
                  <span
                    onClick={() =>
                      dispatch(authActions.setActiveTab("register"))
                    }
                    className={`${styles.new_here} ${"ms-1 pointer"}`}
                  >
                    Click to register
                  </span>
                </p>
              </div>
            </div>
          </form>
        </div>
      )}
      {(step == 2 || step == 3) && (
        <div className={styles.signup_form_wrapper}>
          <form onSubmit={otpFormik.handleSubmit}>
            {step == 2 && (
              <>
                {" "}
                <h1 className="pt-5">Verify OTP</h1>
                <div className="form-item pt-2 pb-3">
                  <OtpInput
                    value={otpFormik.values.otp}
                    onChange={(val) => otpFormik.setFieldValue("otp", val)}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    containerStyle={{ justifyContent: "space-between" }}
                    inputStyle={{ width: "2rem" }}
                    inputType="number"
                  />
                  {otpFormik.touched.otp && otpFormik.errors.otp ? (
                    <small className="text-danger">
                      {otpFormik.errors.otp}
                    </small>
                  ) : null}
                </div>
                <button
                  className={styles.submit_btn}
                  type="submit"
                  disabled={loading}
                >
                  Verify {loading && <Spinner animation="border" size="sm" />}
                </button>
                <button
                  className={styles.resendotp}
                  type="button"
                  onClick={handleResendOtp}
                  disabled={resendTimeout > 0}
                >
                  Resend OTP {resendTimeout > 0 && `(${resendTimeout}s)`}{" "}
                  {sendOtpLoading && <Spinner animation="border" size="sm" />}
                </button>
              </>
            )}
            {step == 3 && (
              <>
                {" "}
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    style={{ width: "16px", padding: "0px" }}
                    type="checkbox"
                    id="html"
                    onChange={(e) => setConsent(e.target.checked)}
                  />
                  <label htmlFor="html" className="form-check-label">
                    {" "}
                    I Accept{" "}
                    <Link href={"/data-privacy-consent"}>
                      <a target="_blank" style={{ color: "#93101d" }}>
                        Personal Data Processing Consent{" "}
                      </a>
                    </Link>
                  </label>
                </div>
                <button
                  className={styles.submit_btn}
                  type="button"
                  onClick={() => statusChange("accept")}
                  disabled={loading || !consent}
                >
                  Submit {loading && <Spinner animation="border" size="sm" />}
                </button>
              </>
            )}{" "}
          </form>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
