import { useAppDispatch } from "@/store";
import { authActions, fetchAuthUser, selectAuth } from "@/store/slices/auth";
import React, { useEffect, useMemo, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import ForgotPasswordForm from "./forgot-password-form";
import LoginForm from "./login-form";
import RegisterForm from "./register-form";
import styles from "../../components/profile-update/updateprofile/updateprofile.module.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import { toast } from "react-toastify";
import { state } from "@/data/state";

const BillingAddressModal = () => {
  const [billingAddressLoading, setBillingAddressLoading] = useState(false);
  const [country, setCountry] = useState<any>([]);
  const [state, setState] = useState<any>([]);

  const dispatch = useAppDispatch();
  const authState = useSelector(selectAuth);
  const hasAccountId = useMemo(
    () => !!authState.user?.account_id,
    [authState.user]
  );

  const formikBillingAddress = useFormik({
    initialValues: {
      country: "",
      state: "",
      city: "",
      pin: "",
      address: "",
    },
    validationSchema: Yup.object({
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      pin: Yup.number()
        .typeError("Please give number")
        .required("Pincode is required")
        .test(
          "len",
          "Pincode needs to be exactly 6 digits",
          (val: any) => val?.toString().length === 6
        ),
      address: Yup.string().required("Address is required"),
    }),
    onSubmit: async (values) => {
      setBillingAddressLoading(true);
      try {
        await httpClient.put(endpoints.auth.billingAddressUpdate(), values);
        toast("Billing Address Updated Successfully", {
          type: "success",
        });
        dispatch(fetchAuthUser());
        dispatch(authActions.setBillingModalOpen(false));
      } catch (error: any) {
        if (error.response?.status === 422) {
          Object.keys(error.response.data?.errors).map((v) => {
            toast(error.response.data.errors[v][0], {
              type: "warning",
            });
          });
        } else {
          toast("Something went wrong", {
            type: "warning",
          });
        }
      }
      setBillingAddressLoading(false);
    },
  });

  const countryLoad = async () => {
    const { data } = await httpClient.get(endpoints.countries());
    setCountry(data.data);
  };

  const stateLoad = async (country: any, value: any) => {
    const { data } = await httpClient.get(endpoints.state(country));
    setState(data.data);
    formikBillingAddress.setFieldValue("state", value);
  };

  useEffect(() => {
    countryLoad();
  }, []);

  useEffect(() => {
    setState([]);

    if (formikBillingAddress?.values?.country?.length) {
      stateLoad(formikBillingAddress.values.country, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikBillingAddress.values.country]);

  return (
    <Modal
      show={authState.billingModalOpen}
      onHide={() => dispatch(authActions.setBillingModalOpen(false))}
      dialogClassName="modal-lg border-0"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        className="p-0"
        closeButton
        onClick={() => dispatch(authActions.setBillingModalOpen(false))}
      ></Modal.Header>
      <Modal.Body className="p-0">
        <div className={styles.new_address}>
          <div className={styles.new_add_form}>
            <div className={styles.bill_head}>Billing Address</div>

            <form onSubmit={formikBillingAddress.handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="required">
                    Country <sup>*</sup>
                  </label>
                  <select
                    className={styles.input_text}
                    {...formikBillingAddress.getFieldProps("country")}
                    disabled={hasAccountId}
                  >
                    <option value="">Select</option>
                    {!!country.length &&
                      country?.map((v: any) => (
                        <option value={v.code} key={v.code}>
                          {v.name}
                        </option>
                      ))}
                  </select>
                  {formikBillingAddress.touched.country &&
                  formikBillingAddress.errors.country ? (
                    <small className="text-danger">
                      {formikBillingAddress.errors.country}
                    </small>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="required">
                    State <sup>*</sup>
                  </label>
                  <select
                    className={styles.input_text}
                    {...formikBillingAddress.getFieldProps("state")}
                    disabled={hasAccountId}
                  >
                    <option value="">Select</option>
                    {!!state.length &&
                      state.map((v: any) => (
                        <option value={v.code} key={v.code}>
                          {v.name}
                        </option>
                      ))}
                  </select>
                  {formikBillingAddress.touched.state &&
                  formikBillingAddress.errors.state ? (
                    <small className="text-danger">
                      {formikBillingAddress.errors.state}
                    </small>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="required">
                    City <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    className={styles.input_text}
                    {...formikBillingAddress.getFieldProps("city")}
                    disabled={hasAccountId}
                  />
                  {formikBillingAddress.touched.city &&
                  formikBillingAddress.errors.city ? (
                    <small className="text-danger">
                      {formikBillingAddress.errors.city}
                    </small>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="required">
                    Pincode <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    className={styles.input_text}
                    maxLength={6}
                    {...formikBillingAddress.getFieldProps("pin")}
                    disabled={hasAccountId}
                  />
                  {formikBillingAddress.touched.pin &&
                  formikBillingAddress.errors.pin ? (
                    <small className="text-danger">
                      {formikBillingAddress.errors.pin}
                    </small>
                  ) : null}
                </div>
                <div className="col-md-12 mb-3">
                  <label className="required">
                    Address <sup>*</sup>
                  </label>
                  <textarea
                    className={styles.input_textarea}
                    rows={2}
                    {...formikBillingAddress.getFieldProps("address")}
                    disabled={hasAccountId}
                  ></textarea>
                  {formikBillingAddress.touched.address &&
                  formikBillingAddress.errors.address ? (
                    <small className="text-danger">
                      {formikBillingAddress.errors.address}
                    </small>
                  ) : null}
                </div>
              </div>

              {!hasAccountId && (
                <button
                  type="submit"
                  className={styles.save}
                  disabled={billingAddressLoading}
                >
                  Save{" "}
                  {billingAddressLoading && (
                    <Spinner animation="border" size="sm" />
                  )}
                </button>
              )}
            </form>
          </div>{" "}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BillingAddressModal;
