import { useAppDispatch } from "@/store";
import { authActions, fetchAuthUser, selectAuth } from "@/store/slices/auth";
import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import offerpopup from "@/assets/images/pop-3.jpg";
import Image from "next/image";
import Link from "next/link";
import styles from "./account-selection-modal.module.scss";
import httpClient from "@/api/http-client";
import endpoints from "@/api/endpoints";
import { toast } from "react-toastify";

const AccountSelectionModal = () => {
  const dispatch = useAppDispatch();
  const authState = useSelector(selectAuth);

  const update = async (values: any) => {
    try {
      await httpClient.put(endpoints.auth.profileUpdate(), values);
      toast("Profile Updated Successfully", {
        type: "success",
      });
      dispatch(fetchAuthUser());
    } catch (error: any) {
      toast("Something went wrong", {
        type: "warning",
      });
    }
  };

  return (
    <Modal
      show={authState.accountSelectionModalOpen}
      dialogClassName="modal-md"
      centered
    >
      <Modal.Body>
        <h4>Choose your default account</h4>
        {(authState.user?.duplicate_records || []).map(
          (record: any, i: number) => (
            <div
              className={`row m-3 p-2 pointer ${styles.option}`}
              key={i}
              style={{ border: "1px solid red", borderRadius: 10 }}
              onClick={() =>
                update({
                  name: record.name,
                  email: record.email,
                  gender: record.gender,
                })
              }
            >
              <div className="col-md-3 d-flex align-items-center">
                <div>
                  <Image
                    src={record.platform?.logo}
                    alt={record.platform?.name}
                    height={50}
                    width={100}
                  />
                </div>
              </div>
              <div className="col-md-9">
                <p>
                  <strong>Platform</strong>: {record.platform?.name}
                </p>
                <p className="mb-0">
                  <strong>Name</strong>: {record.name}
                </p>
                <p className="mb-0">
                  <strong>Email</strong>: {record.email}
                </p>
                <p className="mb-0">
                  <strong>Gender</strong>: {record.gender}
                </p>
              </div>
            </div>
          )
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AccountSelectionModal;
