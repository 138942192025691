import { useAppDispatch } from "@/store";
import { authActions, selectAuth } from "@/store/slices/auth";
import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import offerpopup from "@/assets/images/sign.jpg";
import offerpopupmobile from "@/assets/images/signmobile.jpg";
import offer from "@/assets/images/Desktop_Pop_up.jpg";
import offerm from "@/assets/images/mobile_Pop_up.jpg";
import styles from "../../styles/landing_pop.module.scss";
import Image from "next/image";
import Link from "next/link";

const OfferModal = () => {
  const dispatch = useAppDispatch();
  const authState = useSelector(selectAuth);

  return (
    <Modal
      show={authState.offerModalOpen}
      onHide={() => dispatch(authActions.setIsOfferModalOpen(false))}
      dialogClassName={`${styles.landing_pop} ${" border-0 "}`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        className={`${styles.custom_border_0} ${"p-0"}`}
        closeButton
        onClick={() => dispatch(authActions.setIsOfferModalOpen(false))}
      ></Modal.Header>
      <Modal.Body className={`${styles.offer_modal_new} ${"p-0"}`}>
        <div className={styles.desktop_landing_pop_offer}>
          {/* <Link
            href="https://womensday.everlite.com/?utm_source=Website&utm_medium=SencoGold&utm_campaign=WomensDay"
            passHref
          > */}
          <div className="w-100">
            <Image
              src={offer}
              alt="offer image"
              width={600}
              height={400}
              onClick={() => dispatch(authActions.setIsOfferModalOpen(false))}
            />
          </div>
          {/* </Link> */}
        </div>
        <div className={styles.mobile_landing_pop_offer}>
          {/* <Link
            href="https://womensday.everlite.com/?utm_source=Website&utm_medium=SencoGold&utm_campaign=WomensDay"
            passHref
          > */}
          <div className="w-100">
            <Image
              src={offerm}
              alt="offer image"
              width={400}
              height={600}
              onClick={() => dispatch(authActions.setIsOfferModalOpen(false))}
            />
          </div>
          {/* </Link> */}
        </div>
        {/* <div className="offer_tandc">
          <Link href={"/offer-t-and-c"} passHref>
            <a target="_blank">
              {" "}
              <Image
                src="https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/7yCgP2fShGf4njLKfVF1HA0ESijuxky1MwVIG9Li.png"
                alt="offer image"
                width={13}
                height={60}
              />
            </a>
          </Link>
        </div> */}
      </Modal.Body>
    </Modal>
  );
};

export default OfferModal;
